
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  getCurrentInstance
} from "vue";
import arraySort from "array-sort";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

interface IHeaderConfiguration {
  name?: string;
  key: string;
  sortingField?: string;
  sortable?: boolean;
}

export default defineComponent({
  name: "kt-datatable",
  emit: ["current-change", "sort", "items-per-page-change"],
  props: {
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true
    },
    tableData: { type: Array, required: true },
    emptyTableText: { type: String, default: "No data found" },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    order: { type: String, default: "asc" },
    sortLabel: { type: String, default: "" },
    pageChange: { type: Function, required: true },
    rowsPerPageChange: { type: Function, required: true }
  },
  components: {},
  methods: {
    currentPageChange(page) {
      this.pageChange(page);
    },
    setItemsPerPage(event) {
      this.rowsPerPageChange(event.target.value);
      this.pagination.rowsPerPage = event.target.value
    }
  },
  mounted() {
    this.pagination.total = this.total;
  },
  beforeUnmount() {
    this.pagination.total = 500
  },
  data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 10,
        total: 500
      }
    };
  }
});
